<template>
    <!-- Footer -->
    <section class="footer">
        <div class="container">
            <div class="row">
                <div class="col-md-6 mb-3 mb-md-0">
                    <div class="row">
                        <div class="mb-3 d-flex justify-content-center maps-container">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d55230.85536190207!2d-3.735892523195471!3d42.344182065143066!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd45fd29b90635d5%3A0xd023a595390d9a0d!2sCatedral%20de%20Burgos!5e1!3m2!1ses!2ses!4v1728596223233!5m2!1ses!2ses" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>

                </div>
                <div class="col-md-6">
                <contactCard/>
                </div>
            </div>
        </div>
    </section>
</template>

<style scoped>
    i{
        font-size: 30px;   
    }
    .footer {
        background-color: #f8f9fa;
        padding: 20px 0;
        font-size: 20px;
    }
    .maps-container iframe{
        border-radius: 20px;
        height: 35vh;
        box-shadow: 15px 15px 20px 4px rgba(87, 102, 70, 0.3);

    }
    ul li{
        display: flex;
        align-items: center;
    }
    ul li i{
        padding-right: 1vh;
    }
</style>

<script>
    import contactCard from './contactCard.vue';
    export default {
        name: 'FooterSection',
        components: {
            contactCard,
        },
    };
</script>