<template>
    <div>
        <div id="social-icons" class="social-icons">
            <span class="col-auto">
                <a href="https://twitter.com" target="_blank" aria-label="Twitter"><i class="bi bi-twitter-x"></i></a>
            </span>
            <span class="col-auto">
                <a href="https://instagram.com" target="_blank" aria-label="Instagram"><i class="bi bi-instagram"></i></a>
            </span>
            <span class="col-auto">
                <a href="https://facebook.com" target="_blank" aria-label="Facebook"><i class="bi bi-facebook"></i></a>
            </span>
        </div>
    </div>

</template>

<script>
    export default {
    name: 'RedesSection',
    };
</script>

<style scoped>
    .social-icons{
        top: 35%;
        right: -100%;
        position: fixed;
        display: flex;
        flex-flow: column;
        border-radius: 20px 0px 0px 20px;   
        background-color: #00000070;
    }
    .social-icons i{
        color: whitesmoke !important;
        font-size: 25px;
    }
    .social-icons a{
        display: inline-block;
        height: 50px;
        width: 50px;
        justify-content: center;
        text-align: center;
        align-content: center
    }
    .col-auto a:hover{
        background-color: #8aca4180;
        border-radius: 20px 0px 0px 20px;   
    }
    .social-icons{
        right:0%;
        -webkit-animation-name:showSocialIcons;
        animation-name:showSocialIcons;
        transform:translateX(100%);
        animation-delay: 5s;
        -webkit-animation-duration:1.5s;
        animation-duration:1.5s;
        -webkit-animation-iteration-count:1;
        animation-iteration-count:1;
        -webkit-animation-fill-mode:forwards;
        animation-fill-mode:forwards
    }
    @keyframes showSocialIcons{
        from{-webkit-transform:translateX(100%);transform:translateX(100%)
    }
        to{-webkit-transform:translateX(0%);transform:translateX(0%)
    }
    }
/* Puedes agregar estilos adicionales aquí si es necesario */
</style>
