<template>
<section class="full-video-div">
    <video class="video-container" autoplay="" loop="" muted="" ref="videoPlayer">
        <source src="../assets/intro.mp4" type="video/mp4">
        </video>
    <div class="content">
        <div class="row" style="position: absolute;">
            <h1 class="title-intro text-center"><span>Adrero</span></h1>
            <h2 class="subtitle-intro text-center"><span>test</span></h2>
        </div>
    </div>
    <!-- btn scrool -->
    <a href="#" class="scroll-down" address="true" v-on:click="asd"></a>
</section>
</template>

<script>
    export default {
    name: 'VideoFull',
    mounted() {
        document.addEventListener('visibilitychange', this.handleVisibilityChange);
    },
    beforeUnmount() {
        document.removeEventListener('visibilitychange', this.handleVisibilityChange);
    },
    methods: {
        handleVisibilityChange() {
            const video = this.$refs.videoPlayer;
            if (document.hidden) {
                video.pause();
            }else{
                video.play();
            }
        },
        asd(){
            this.$emit('input-video', true);
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .full-video-div{
        width: 100%;
        left: 0;
        top: 0;
        z-index: 31;
        position: fixed;
    }
    .video-container {
        position: absolute;
        top: 50%;
        left: 50%;
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
        z-index: -1;
        transform: translate(-50%, -50%);
    }
    .content{
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh;
        color: white;
    }
    *,
    :after,
    :before {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
    }

    section {
        height: 100vh;
        width: 100%;
        display: table;
    }
    section.ok{
        background-color: #555;
    }

    p{
        color: white;
        font-family: arial;
        display: table-cell;
        text-align: center;
        vertical-align: middle;
    }

    .scroll-down {
        opacity: 1;
        -webkit-transition: all .5s ease-in 3s;
        transition: all .5s ease-in 3s;
    }

    .scroll-down {
        position: absolute;
        bottom: 5vh;
        left: 50%;
        margin-left: -16px;
        display: block;
        width: 52px;
        height: 52px;
        border: 2px solid #FFF;
        background-size: 14px auto;
        border-radius: 50%;
        z-index: 2;
        -webkit-animation: bounce 2s infinite 2s;
        animation: bounce 2s infinite 2s;
        -webkit-transition: all .2s ease-in;
        transition: all .2s ease-in;
    }
    @media screen and (max-width: 600px) {
        .scroll-down {
            bottom: 10vh;
        }
    }
    .scroll-down:before {
        position: absolute;
        top: calc(50% - 8px);
        left: calc(50% - 6px);
        transform: rotate(-45deg);
        display: block;
        width: 12px;
        height: 12px;
        content: "";
        border: 2px solid white;
        border-width: 0px 0 2px 2px;
    }
    
    .title-intro span{
        -webkit-transform:translateY(-100%);
        transform:translateY(-100%);
        display:inline-block;
        text-align:center;
        -webkit-animation-name:showTitleIntro;
        animation-name:showTitleIntro;
        -webkit-animation-duration:1.5s;
        animation-duration:1.5s;
        -webkit-animation-iteration-count:1;
        animation-iteration-count:1;
        -webkit-animation-fill-mode:forwards;
        animation-fill-mode:forwards;
        font-size:52px;
        letter-spacing:3.3px;

    }
    .subtitle-intro{
        text-transform:uppercase;
        font-size:24px;
        font-weight:300;
        letter-spacing:3.3px;
        text-align:center;
        width:100%;
        display:inline-block;
        height:30px;
        overflow:hidden
    }
    .subtitle-intro span{
        display:inline-block;
        -webkit-animation-name:showTitleIntro;
        animation-name:showTitleIntro;
        transform:translateY(-100%);
        animation-delay: 1.5s;
        -webkit-animation-duration:1.5s;
        animation-duration:1.5s;
        -webkit-animation-iteration-count:1;
        animation-iteration-count:1;
        -webkit-animation-fill-mode:forwards;
        animation-fill-mode:forwards
    }
    .launched .subtitle-intro span{-webkit-transform:translateY(0);transform:translateY(0)
    }
    @keyframes bounce {
        0%,
        100%,
        20%,
        50%,
        80% {
            -webkit-transform: translateY(0);
            -ms-transform: translateY(0);
            transform: translateY(0);
        }
        40% {
            -webkit-transform: translateY(-10px);
            -ms-transform: translateY(-10px);
            transform: translateY(-10px);
        }
        60% {
            -webkit-transform: translateY(-5px);
            -ms-transform: translateY(-5px);
            transform: translateY(-5px);
        }
    }
    @-webkit-keyframes showTitleIntro{
        from{-webkit-transform:translateY(-100%);transform:translateY(-100%)
    }
        to{-webkit-transform:translateY(0%);transform:translateY(0%)
    }
    }
    @keyframes showTitleIntro{
        from{-webkit-transform:translateY(-100%);transform:translateY(-100%)
    }
        to{-webkit-transform:translateY(0%);transform:translateY(0%)
    }
    }
</style>
