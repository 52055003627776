<template>
    <section class="py-16 bg-gray-100">
        <h1> test</h1>
    </section>
</template>

<script>
    export default {
    name: 'InfoSection',
    };
</script>

<style scoped>
section{
    width: 100%;
    height: 100vh;
}
/* Puedes agregar estilos adicionales aquí si es necesario */
</style>
