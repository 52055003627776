<template>
    <section class="py-16 bg-gray-100">
        <div class="column-container">
            <div class="row">
                <!-- <div class="left-column col-md-6"> -->
                    <div class="left-column col-md-6" @mouseover="videoHover(true)" @mouseleave="videoHover(false)">
                        <video muted loop ref="videoExp">
                            <source src="../assets/intro.mp4" type="video/mp4">
                            Your browser does not support the video tag.
                        </video>
                        <div class="content">
                            <span class="paramos">La comarca de páramos un entorno único que deja su huella en todo lo que hacemos</span>
                            <button class="roundButton">Páramos</button>
                        </div>
                    </div>

                <!-- </div> -->
                <div class="right-column col-md-6">
                    <div class="row img-row" @mouseover="infoHover(0,true)" @mouseleave="infoHover(0,false)">
                        <div class="churras">
                            <button class="roundButton">Raza Churra</button>
                        </div>

                    </div>
                    <div class="row img-row">
                        <div class="extensive" @mouseover="infoHover(1,true)" @mouseleave="infoHover(1,false)">
                            <button class="roundButton">En Extensivo</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </section>
</template>

<script>

    export default {
    name: 'ExpSection',
    methods: {
        videoHover(active) {
            const video = this.$refs.videoExp;
            if (active) {
                video.play();
            }else{
                video.pause();
            }
        },
        infoHover(index, active) {
            let heightInVH = 50;
            const items = document.querySelectorAll('.img-row');
            const divElement = document.querySelector('.right-column');
        
            const height = divElement.offsetHeight;
            const viewportHeight = window.innerHeight;

            // Calcula los vh correspondientes
            heightInVH = Math.round((height / viewportHeight) * 100);
            if (active) {
                items.forEach(item => {
                    item.style.height = `${heightInVH/4}vh`;
                });
                items[index].style.height = `${heightInVH*.75}vh`;

            }else{
                items.forEach(item => {
                    item.style.height = `${heightInVH/2}vh`;
                });
            }
        }   
    }
    };

</script>

<style scoped>
    section{
        width: 100%;
        height: fit-content;
        overflow-x: hidden;
    }
    .column-container{
        width: 100%;
        height: 100vh;
    }
    .right-column{
        position: relative;
        height: 100vh; /* Set height, you can change this to any desired value */
        overflow: hidden;
    }
    .right-column .row{
        height: 50vh;
    }
    .left-column {
        position: relative;
        height: 100vh; /* Set height, you can change this to any desired value */
        overflow: hidden;
        opacity: 0; /* Initially hidden */
        transform: translateX(100%); /* Positioned off-screen to the left */
        animation: slideInRight 2s forwards ease-in-out; /* Trigger the animation */
    }

    .left-column video {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        object-fit: cover; /* Ensures the video covers the entire div */
        transform: translate(-50%, -50%);
        z-index: -1; /* Keep the video in the background */
    }
    .paramos{
        font-size: 2vh;
        padding-bottom: 4vh;
        max-width: 40%;
        word-spacing: 0.6%;
    }
    .content {
        position: relative;
        z-index: 1; /* Ensure content is above the video */
        color: white;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100vh;
    }
    .churras{
        background: url('@/assets/churra.jpg') 50%/cover no-repeat;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        opacity: 0; /* Initially hidden */
        transform: translateX(-100%); /* Positioned off-screen to the left */
        animation: slideInLeft 1s forwards ease-in-out; /* Trigger the animation */
        animation-delay: 2s;
    }
    .img-row{
        transition: all 0.9s ease;
    }
    .extensive{
        background: url('@/assets/extensivo.jpg') 50%/cover no-repeat;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        opacity: 0; /* Initially hidden */
        transform: translateX(-100%); /* Positioned off-screen to the left */
        animation: slideInLeft 1s forwards ease-in-out; /* Trigger the animation */
        animation-delay: 3s;

    }
    .roundButton{
        min-width: 30%;
    }
    /* mobile */
    @media screen and (max-width: 600px) {
        .paramos{
            max-width: 70%;
        }
        .left-column {
            height: 50vh;
        }
        .right-column{
            height: 50vh;
        }
        .right-column .row{
            height: 25vh;
        }
        .content {
            height: 50vh;
        }
    }
    /* Efectos */
    @keyframes slideInLeft {
        0% {
            opacity: 0;
            transform: translateX(100%);
        }
        100% {
            opacity: 1;
            transform: translateX(0);
        }
    }
    @keyframes slideInRight {
        0% {
            opacity: 0;
            transform: translateX(-100%);
        }
        100% {
            opacity: 1;
            transform: translateX(0);
        }
    }
</style>