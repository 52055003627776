<template>
    <div class="card">
        <div class="card-details">
        <p class="text-title">¿Como encontrarnos?</p>
        <ul class="list-unstyled text-start">
            <li class="card__list_item">
                <i class="bi bi-geo-alt-fill"></i>
                <span class="list_text">Dirección: Calle Falsa 123, Ciudad, País</span>
            </li>
            <li class="card__list_item">
                <i class="bi bi-envelope-fill"></i> 
                <span class="list_text">Email: contacto@empresa.com</span>
            </li>
            <li class="card__list_item">
                <i class="bi bi-telephone-fill"></i>
                <span class="list_text">Teléfono: +123 456 7890</span>
            </li>
        </ul>
    </div>
    <button class="cardButton">Enviar un mensaje</button>
</div>    
</template>

<script>
    export default {
        name: 'ContactCard2',
    };
</script>


<style scoped>
.card {
    width: 100%;
    height: 35vh;
    border-radius: 20px;
    background: #f5f5f5;
    position: relative;
    padding: 1.8rem;
    border: 2px solid #c3c6ce;
    transition: 0.5s ease-out;
    overflow: visible;
}

.card-details {
    color: black;
    height: 100%;
    gap: .5em;
    display: grid;
    place-content: center;
}

.cardButton {
    transform: translate(-50%, 125%);
    width: 60%;
    border-radius: 18px;
    border: 1px solid #6ba32afd;
    color: #6ba32afd;
    font-size: 1rem;
    padding: .5rem 1rem;
    position: absolute;
    left: 50%;
    bottom: 0;
    opacity: 0;
    transition: 0.3s ease-out;
    overflow: hidden;
    background-color: #8aca4159;
    color: #ffffff;

}
.cardButton:hover {
    color: #ffffff;
    transform: scale(1.1);
    outline: 2px solid #8cce4280;
    box-shadow: 4px 5px 17px -4px #71a73580;
    }
.cardButton::before {
    content: "";
    position: absolute;
    left: -50px;
    top: 0;
    width: 0;
    height: 100%;
    background-color: #8aca41cb;
    transform: skewX(45deg);
    z-index: -1;
    transition: width 750ms;
}
.cardButton:hover::before {
    width: 250%;
}
.text-body {
    color: rgb(134, 134, 134);
}

/*Text*/
.text-title {
    font-size: 1.5em;
    font-weight: bold;
}
.card__list_item{
    display: flex;
    align-items: center;
}
.card__list_item i {
    padding-right: 1vh;
    font-size: 3vh;
}
@media screen and (max-width: 600px) {
    .text-title{
        margin: 0;
    }
    .card{
        padding: 1rem;
    }
    .list_text{
        font-size: 15px;
    }
}
/*Hover*/
.card:hover {
    border-color: #6ba32afd;
    /* box-shadow: 0 4px 18px 0 rgba(87, 102, 70, 0.3); */
    box-shadow: 15px 15px 20px 4px rgba(87, 102, 70, 0.3);
}

.card:hover .cardButton {
    transform: translate(-50%, 50%);
    opacity: 1;
}
</style>
