<template>
    <div>
      <VideoFull @input-video="handleVideo" v-if="!showVideo"/>
      <div class="sectionContainer">
        <HeaderSection v-if="showVideo"/>
        <ExpSection v-if="showVideo"/>
        <InfoSection v-if="showVideo"/>
        <FooterSection v-if="showVideo"/>
        <RedesSection v-if="showVideo"/>
        <ContactCard/>
      </div>
  </div>
</template>

<script>
  import VideoFull from './components/VideoFull.vue'
  import InfoSection from './components/Info.vue'
  import FooterSection  from './components/Footer.vue'
  import RedesSection from './components/RedesSociales.vue';
  import HeaderSection from './components/Header.vue';
  import ExpSection from './components/Exp.vue';
  export default {
    name: 'App',
    components: {
      VideoFull,
      InfoSection,
      FooterSection,
      RedesSection,
      HeaderSection,
      ExpSection
    },
    data() {
      return {
        showVideo: false
      }
    },
    methods: {
      handleVideo(data){
        this.showVideo = data;
        // Activar panle rrss
        // document.getElementById('social-icons').classList.add('active');
      }
    }
  }
</script>

<style>
  body{
    margin: 0;
  }
  #app {
    font-family: Montserrat, Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: white;
  }
  .sectionContainer{
    position: relative;
  }
  .roundButton{
    color: #fff;
    text-transform: uppercase;
    padding: 10px 20px;
    border: 1px solid #fff;
    background-color: transparent;
    border-radius: 18px;
    position: relative;
    overflow: hidden;
    transition: all 750ms;
}
.roundButton:hover {
  color: #ffffff;
  transform: scale(1.1);
  outline: 2px solid #8cce4280;
  box-shadow: 4px 5px 17px -4px #71a73580;
}
.roundButton::before {
  content: "";
  position: absolute;
  left: -50px;
  top: 0;
  width: 0;
  height: 100%;
  background-color: #8aca4159;
  transform: skewX(45deg);
  z-index: -1;
  transition: width 750ms;
}
.roundButton:hover::before {
  width: 250%;
}

</style>
